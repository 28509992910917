import { VitalHeightNodeData } from 'EntityTypes';

import { Note2NodeSpec } from '../../node-specs/note2NodeSpecs';

export class VitalHeightNodeSpec implements Note2NodeSpec<VitalHeightNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  isEmpty(nodeData: VitalHeightNodeData): boolean {
    return !nodeData.attrs.vniheight?.description;
  }

  merge(_nodeData: VitalHeightNodeData, _otherNodeData: VitalHeightNodeData): false {
    return false;
  }
}

export const vitalHeightNodeSpec = new VitalHeightNodeSpec();
