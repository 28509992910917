import { TextInputFormik } from '@el8/vital-formik';
import React, { useContext, useEffect } from 'react';
import { Group } from '@el8/vital';
import noop from 'lodash/noop';
import { FormikProps, withFormik, WithFormikConfig } from 'formik';

import { VitalsCollection } from 'EntityTypes';
import { NumberInputFormik } from 'components/forms';
import { VITALS_EXTRA_NOTE_MAX_LENGTH } from 'modules/visit-notes';
import { alertError } from 'utils/errors';
import useAppFormikContext from 'utils/forms/useAppFormikContext';

import { createNote2VitalDescription, useSaveNote2Vital } from './helpers';
import { VitalWeightNodeProps } from './VitalWeightNode';
import { Note2VitalContext } from './Note2VitalContextProvider';

type Values = {
  weight: number | null;
  note: string;
};

interface NonFormikProps extends Pick<VitalWeightNodeProps, 'node' | 'updateAttributes'> {
  vitalsCollection: VitalsCollection | undefined;
}

interface Props extends NonFormikProps, FormikProps<Values> {}

const withFormikConfig: WithFormikConfig<NonFormikProps, Values> = {
  enableReinitialize: true,
  mapPropsToValues({ vitalsCollection }) {
    return {
      weight: vitalsCollection?.weight ? Number(vitalsCollection.weight) : null,
      note: vitalsCollection?.wt_note || '',
    };
  },
  handleSubmit: noop,
};

/**
 * A form for creating weight vitals in a `VitalWeightNode`.
 */
function VitalWeightNodeForm({ node, updateAttributes, vitalsCollection }: Props): JSX.Element {
  const { dirty, values } = useAppFormikContext<Values>();

  const vitalId = node.attrs.vniweight?.id;

  const vitalStore = useContext(Note2VitalContext);

  const saveWeightVital = useSaveNote2Vital('weight', vitalId, {
    updateReferencedEntities: (vital, attributes) => {
      const description = createNote2VitalDescription(`${vital.weight} ${vital.units}`, vital);

      updateAttributes({
        vniweight: { id: vital.id, description },
        ...attributes,
      });
    },
  });

  const handleBlur = async (): Promise<void> => {
    if (dirty) {
      try {
        await saveWeightVital({
          weight: values.weight != null ? String(values.weight) : '',
          extra_note: values.note,
        });
      } catch (err) {
        alertError(err);
      }
    }
  };

  useEffect(() => {
    if (vitalsCollection && vitalStore) {
      vitalStore.setWeight(vitalsCollection.weight ? Number(vitalsCollection.weight) : null);
    }
  }, [vitalStore, vitalsCollection]);

  return (
    <Group contentEditable={false} verticallyAlign="center">
      <NumberInputFormik
        aria-label="Weight"
        mode="decimal"
        name="weight"
        onBlur={handleBlur}
        placeholder="lbs"
        style={{ width: 60 }}
      />
      <TextInputFormik
        aria-label="Note"
        maxLength={VITALS_EXTRA_NOTE_MAX_LENGTH}
        name="note"
        onBlur={handleBlur}
        placeholder="note..."
        style={{ flex: 1 }}
      />
    </Group>
  );
}

export default withFormik(withFormikConfig)(VitalWeightNodeForm);
