import { VitalRRNodeData } from 'EntityTypes';
import { Note2NodeSpec } from '../../node-specs/note2NodeSpecs';

export class VitalRRNodeSpec implements Note2NodeSpec<VitalRRNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  isEmpty(nodeData: VitalRRNodeData): boolean {
    return !nodeData.attrs.vnirr?.description;
  }

  merge(_nodeData: VitalRRNodeData, _otherNodeData: VitalRRNodeData): false {
    return false;
  }
}

export const vitalRRNodeSpec = new VitalRRNodeSpec();
