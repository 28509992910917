import { VitalWeightNodeData } from 'EntityTypes';

import { Note2NodeSpec } from '../../node-specs/note2NodeSpecs';

export class VitalWeightNodeSpec implements Note2NodeSpec<VitalWeightNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  isEmpty(nodeData: VitalWeightNodeData): boolean {
    return !nodeData.attrs.vniweight?.description;
  }

  merge(_nodeData: VitalWeightNodeData, _otherNodeData: VitalWeightNodeData): false {
    return false;
  }
}

export const vitalWeightNodeSpec = new VitalWeightNodeSpec();
