import { VitalOxygenNodeData } from 'EntityTypes';

import { Note2NodeSpec } from '../../node-specs/note2NodeSpecs';

export class VitalOxygenNodeSpec implements Note2NodeSpec<VitalOxygenNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  isEmpty(nodeData: VitalOxygenNodeData): boolean {
    return !nodeData.attrs.vnioxygen?.description;
  }

  merge(_nodeData: VitalOxygenNodeData, _otherNodeData: VitalOxygenNodeData): false {
    return false;
  }
}

export const vitalOxygenNodeSpec = new VitalOxygenNodeSpec();
