import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Text2 } from '@el8/vital';

import { alertError } from 'utils/errors';
import { TypedNodeViewProps } from 'utils/tiptap';
import { usePrevious } from 'utils/hooks';
import { VitalBMINodeAttributes } from 'EntityTypes';

import { useLatestHeightInitialization, useSaveNote2Vital } from './helpers';
import { Note2VitalContext } from './Note2VitalContextProvider';

const calculateBMI = (
  height: number | null | undefined,
  weight: number | null | undefined,
): string | null => {
  if (height && weight) {
    const n = (weight / height ** 2) * 703;
    return n.toFixed(2);
  }
  return null;
};

interface VitalBMINodeFormProps
  extends Pick<TypedNodeViewProps<VitalBMINodeAttributes>, 'node' | 'updateAttributes'> {
  bmiVital: string | null;
}

/**
 * A Note 2.0 custom node for viewing BMI vitals information.
 */
function VitalBMINodeForm({
  bmiVital,
  node,
  updateAttributes,
}: VitalBMINodeFormProps): JSX.Element {
  const vitalId = node.attrs.vnibmi?.id;

  const vitalStore = useContext(Note2VitalContext);
  const height = vitalStore?.height;
  const weight = vitalStore?.weight;
  const previousHeight = usePrevious(height);
  const previousWeight = usePrevious(weight);
  const [bmi, setBMI] = useState(bmiVital);

  const initializeLatestHeight = useLatestHeightInitialization();

  const initializeToLatestHeight = useCallback(async (): Promise<void> => {
    await initializeLatestHeight();
    // el8-migrate: DO NOT COPY PASTE THIS LINE TO SILENCE YOUR ERRORS
    // The following line was baselined when the rule was changed to an error.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Currently, the vitals collection is not tied directly to a Note2
  // this means that the BMI might pull the latest height from a different Note2
  // Once https://elationhealth.atlassian.net/browse/RM-39041 is complete, the logic should be
  // * if there is no vitals collection associated with the node/node, default to the latest height
  // * if there is a vitals collection associated with the note, check that the collection has a height
  //   * if it does, initialize to that height
  //   * if not, default to latest height
  // see: https://github.com/elationemr/hippo/pull/13629#discussion_r1228573683
  useEffect(function defaultToLatestHeightIfNoStoredHeight() {
    initializeToLatestHeight();
    // el8-migrate: DO NOT COPY PASTE THIS LINE TO SILENCE YOUR ERRORS
    // The following line was baselined when the rule was changed to an error.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveBMIVital = useSaveNote2Vital('bmi', vitalId, {
    updateReferencedEntities: (_, attributes) => {
      updateAttributes(attributes);
    },
  });

  const saveNewBMI = useCallback(
    async (newBMI: string | null): Promise<void> => {
      if (bmi !== newBMI) {
        try {
          await saveBMIVital({
            bmi: newBMI,
          });
        } catch (err) {
          alertError(err);
        }
      }
    },
    [bmi, saveBMIVital],
  );

  useEffect(() => {
    if (height !== previousHeight || weight !== previousWeight) {
      const newBMI = calculateBMI(height, weight);
      if (newBMI !== bmi) {
        saveNewBMI(newBMI);
        setBMI(newBMI);
      }
    }
    // el8-migrate: DO NOT COPY PASTE THIS LINE TO SILENCE YOUR ERRORS
    // The following line was baselined when the rule was changed to an error.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousHeight, previousWeight, vitalStore, saveNewBMI, bmi]);

  return (
    <Text2 aria-label="BMI" contentEditable={false}>
      {bmi || ''}
    </Text2>
  );
}

export default VitalBMINodeForm;
