import { TextInputFormik } from '@el8/vital-formik';
import React from 'react';
import { Group } from '@el8/vital';
import noop from 'lodash/noop';
import { FormikProps, withFormik, WithFormikConfig } from 'formik';

import { VitalsCollection } from 'EntityTypes';
import { NumberInputFormik } from 'components/forms';
import { VITALS_EXTRA_NOTE_MAX_LENGTH } from 'modules/visit-notes';
import { alertError } from 'utils/errors';
import useAppFormikContext from 'utils/forms/useAppFormikContext';

import { VitalPainNodeProps } from './VitalPainNode';
import { createNote2VitalDescription, useSaveNote2Vital } from './helpers';

type Values = {
  pain: number | null;
  note: string;
};

interface NonFormikProps extends Pick<VitalPainNodeProps, 'node' | 'updateAttributes'> {
  vitalsCollection: VitalsCollection | undefined;
}

interface Props extends NonFormikProps, FormikProps<Values> {}

const withFormikConfig: WithFormikConfig<NonFormikProps, Values> = {
  enableReinitialize: true,
  mapPropsToValues({ vitalsCollection }) {
    return {
      pain: vitalsCollection?.pain ? Number(vitalsCollection.pain) : null,
      note: vitalsCollection?.pain_note || '',
    };
  },
  handleSubmit: noop,
};

/**
 * A form for creating pain vitals in a `VitalPainNode`.
 */
function VitalPainNodeForm({ node, updateAttributes }: Props): JSX.Element {
  const { dirty, values } = useAppFormikContext<Values>();

  const vitalId = node.attrs.vnipain?.id;

  const savePainVital = useSaveNote2Vital('pain', vitalId, {
    updateReferencedEntities: (vital, attributes) => {
      const description = createNote2VitalDescription(vital.pain, vital);

      updateAttributes({
        vnipain: { id: vital.id, description },
        ...attributes,
      });
    },
  });

  const handleBlur = async (): Promise<void> => {
    if (dirty) {
      try {
        await savePainVital({
          pain: values.pain != null ? String(values.pain) : '',
          extra_note: values.note,
        });
      } catch (err) {
        alertError(err);
      }
    }
  };

  return (
    <Group contentEditable={false} verticallyAlign="center">
      <NumberInputFormik
        aria-label="Pain"
        mode="integer"
        name="pain"
        onBlur={handleBlur}
        placeholder="1-10"
        style={{ width: 50 }}
      />
      <TextInputFormik
        aria-label="Note"
        maxLength={VITALS_EXTRA_NOTE_MAX_LENGTH}
        name="note"
        onBlur={handleBlur}
        placeholder="note..."
        style={{ flex: 1 }}
      />
    </Group>
  );
}

export default withFormik(withFormikConfig)(VitalPainNodeForm);
