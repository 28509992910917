import { TextInputFormik } from '@el8/vital-formik';
import React from 'react';
import { Group } from '@el8/vital';
import noop from 'lodash/noop';
import { FormikProps, withFormik, WithFormikConfig } from 'formik';

import { VitalsCollection } from 'EntityTypes';
import { NumberInputFormik } from 'components/forms';
import { VITALS_EXTRA_NOTE_MAX_LENGTH } from 'modules/visit-notes';
import { alertError } from 'utils/errors';
import useAppFormikContext from 'utils/forms/useAppFormikContext';

import { VitalRRNodeProps } from './VitalRRNode';
import { createNote2VitalDescription, useSaveNote2Vital } from './helpers';

type Values = {
  rr: number | null;
  note: string;
};

interface NonFormikProps extends Pick<VitalRRNodeProps, 'node' | 'updateAttributes'> {
  vitalsCollection: VitalsCollection | undefined;
}

interface Props extends NonFormikProps, FormikProps<Values> {}

const withFormikConfig: WithFormikConfig<NonFormikProps, Values> = {
  enableReinitialize: true,
  mapPropsToValues({ vitalsCollection }) {
    return {
      rr: vitalsCollection?.rr ? Number(vitalsCollection.rr) : null,
      note: vitalsCollection?.rr_note || '',
    };
  },
  handleSubmit: noop,
};

/**
 * A form for creating respiration rate vitals in a `VitalRRNode`.
 */
function VitalRRNodeForm({ node, updateAttributes }: Props): JSX.Element {
  const { dirty, values } = useAppFormikContext<Values>();

  const vitalId = node.attrs.vnirr?.id;

  const saveRRVital = useSaveNote2Vital('rr', vitalId, {
    updateReferencedEntities: (vital, attributes) => {
      const description = createNote2VitalDescription(vital.rr, vital);

      updateAttributes({
        vnirr: { id: vital.id, description },
        ...attributes,
      });
    },
  });

  const handleBlur = async (): Promise<void> => {
    if (dirty) {
      try {
        await saveRRVital({
          rr: values.rr != null ? String(values.rr) : '',
          extra_note: values.note,
        });
      } catch (err) {
        alertError(err);
      }
    }
  };

  return (
    <Group contentEditable={false} verticallyAlign="center">
      <NumberInputFormik
        aria-label="Respiration rate"
        mode="decimal"
        name="rr"
        onBlur={handleBlur}
        placeholder="RR"
        style={{ width: 50 }}
      />
      <TextInputFormik
        aria-label="Note"
        maxLength={VITALS_EXTRA_NOTE_MAX_LENGTH}
        name="note"
        onBlur={handleBlur}
        placeholder="note..."
        style={{ flex: 1 }}
      />
    </Group>
  );
}

export default withFormik(withFormikConfig)(VitalRRNodeForm);
