import { VitalTemperatureNodeData } from 'EntityTypes';

import { Note2NodeSpec } from '../../node-specs/note2NodeSpecs';

export class VitalTemperatureNodeSpec implements Note2NodeSpec<VitalTemperatureNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  isEmpty(nodeData: VitalTemperatureNodeData): boolean {
    return !nodeData.attrs.vnitemperature?.description;
  }

  merge(_nodeData: VitalTemperatureNodeData, _otherNodeData: VitalTemperatureNodeData): false {
    return false;
  }
}

export const vitalTemperatureNodeSpec = new VitalTemperatureNodeSpec();
