import { VitalHRNodeData } from 'EntityTypes';
import { Note2NodeSpec } from '../../node-specs/note2NodeSpecs';

export class VitalHRNodeSpec implements Note2NodeSpec<VitalHRNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  isEmpty(nodeData: VitalHRNodeData): boolean {
    return !nodeData.attrs.vnihr?.description;
  }

  merge(_nodeData: VitalHRNodeData, _otherNodeData: VitalHRNodeData): false {
    return false;
  }
}

export const vitalHRNodeSpec = new VitalHRNodeSpec();
