import { VitalPainNodeData } from 'EntityTypes';

import { Note2NodeSpec } from '../../node-specs/note2NodeSpecs';

export class VitalPainNodeSpec implements Note2NodeSpec<VitalPainNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  isEmpty(nodeData: VitalPainNodeData): boolean {
    return !nodeData.attrs.vnipain?.description;
  }

  merge(_nodeData: VitalPainNodeData, _otherNodeData: VitalPainNodeData): false {
    return false;
  }
}

export const vitalPainNodeSpec = new VitalPainNodeSpec();
