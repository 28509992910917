import { Editor } from '@tiptap/core';
import { UseQueryResult } from '@tanstack/react-query';

import {
  BaseVital,
  NoteReferencedEntityData,
  VitalName,
  VitalNameToModel,
  VitalsCollection,
} from 'EntityTypes';
import {
  CreateVitalRequestBody,
  GetVitalsCollectionResponseBody,
  UpdateVitalRequestBody,
} from 'QueryTypes';
import { getPatientId, useCurrentPatient } from 'modules/patient';
import {
  latestVitalsQuery,
  useCreateVital,
  useGetVitalsCollection,
  useUpdateVital,
} from 'modules/patient-vitals';
import { useCurrentPractice } from 'modules/practice';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { requestAsync } from 'redux-query';
import { checkSuccess } from 'utils/http';
import { useContext } from 'react';
import { Note2VitalContext } from './Note2VitalContextProvider';

/**
 * Returns the `description` text for a vital in Note 2.0.
 */
export function createNote2VitalDescription(valueText: string, vital: BaseVital): string {
  let description = valueText;
  if (vital.extra_note) {
    description += ` (${vital.extra_note})`;
  }
  return description;
}

/**
 * A VisitNote2-specific variant of `useGetVitalsCollection` that ensures it's only
 * enabled while the note is active for editing, since readonly notes don't need
 * any `VitalsCollection` data and we shouldn't do wasteful fetches in that case.
 */
export function useNote2VitalsCollection(
  editor: Editor,
  vitalsCollectionId: number | undefined,
): UseQueryResult<GetVitalsCollectionResponseBody> {
  const [currentPatient] = useCurrentPatient();

  return useGetVitalsCollection(currentPatient?.id, vitalsCollectionId, {
    enabled: Boolean(editor.isEditable && currentPatient && vitalsCollectionId),
  });
}

/**
 * Utility hook that returns a function to save a vital in Note 2.0. Internally,
 * the returned function will either create a new vital or update an existing vital
 * depending on whether the `vitalId` argument is defined.
 */
export function useSaveNote2Vital<TVitalName extends VitalName>(
  vitalName: TVitalName,
  vitalId: number | undefined,
  params: {
    /**
     * A function that can be called to update the node's referenced entity
     * attributes with the newly saved vital.
     */
    updateReferencedEntities(
      /** The saved vital. */
      vital: VitalNameToModel[TVitalName],
      /**
       * Node attributes that should be spread onto the argument passed into
       * the `updateAttributes` call for updating the referenced entities.
       */
      attributes: { vitals_collection?: NoteReferencedEntityData },
    ): void;
  },
): (
  payload: CreateVitalRequestBody<TVitalName> | UpdateVitalRequestBody<TVitalName>,
) => Promise<void> {
  const { updateReferencedEntities } = params;

  const currentPractice = useCurrentPractice();
  const [currentPatient] = useCurrentPatient();

  const createVital = useCreateVital(vitalName);
  const updateVital = useUpdateVital(vitalName);

  const saveNote2Vital = async (
    payload: CreateVitalRequestBody<TVitalName> | UpdateVitalRequestBody<TVitalName>,
  ): Promise<void> => {
    if (!currentPatient) throw new Error(`Can't save '${vitalName}' vital with no currentPatient`);

    let savedVital: VitalNameToModel[TVitalName];
    let newVitalsCollection: VitalsCollection | undefined;
    if (vitalId) {
      savedVital = await updateVital.mutateAsync({
        body: payload,
        patientId: currentPatient.id,
        practiceId: currentPractice.id,
        vitalId,
      });
    } else {
      ({ data: savedVital, vitals_collection: newVitalsCollection } = await createVital.mutateAsync(
        {
          body: payload,
          patientId: currentPatient.id,
          practiceId: currentPractice.id,
        },
      ));
    }

    const attributes: { vitals_collection?: NoteReferencedEntityData } = {};
    if (newVitalsCollection) {
      attributes.vitals_collection = {
        id: newVitalsCollection.id,
        description: '',
      };
    }

    // FUTURE: Possible `flushSync` error in saveNote2Vital / updateReferencedEntities if vitals
    // are removed or unmounted while request is in-flight? Can we check or cancel mutation?
    updateReferencedEntities(savedVital, attributes);
  };

  return saveNote2Vital;
}

/**
 * Utility hook that returns a function to retrieve and set the latest height
 * vital for the current patient.
 */
export function useLatestHeightInitialization(): (
  initializer?: (height: string) => Promise<void>,
) => Promise<void> {
  const dispatch = useAppDispatch();
  const patientId = useAppSelector(getPatientId);
  const vitalStore = useContext(Note2VitalContext);

  const initializeLatestHeight = async (
    initializer?: (height: string) => Promise<void>,
  ): Promise<void> => {
    try {
      const res = await dispatch(
        requestAsync({ ...latestVitalsQuery(patientId, 'height'), force: true }),
      );
      checkSuccess(res);

      if (res.body.height) {
        const { height } = res.body.height;

        if (initializer) {
          await initializer(height);
        }
        if (vitalStore) {
          vitalStore.setHeight(Number(height));
        }
      }
    } catch (err) {
      console.warn('Failed to initialize to latest height', err);
    }
  };

  return initializeLatestHeight;
}
