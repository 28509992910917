import { VitalBMINodeData } from 'EntityTypes';

import { Note2NodeSpec } from '../../node-specs/note2NodeSpecs';

export class VitalBMINodeSpec implements Note2NodeSpec<VitalBMINodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  isEmpty(nodeData: VitalBMINodeData): boolean {
    return !nodeData.attrs.vnibmi;
  }

  merge(_nodeData: VitalBMINodeData, _otherNodeData: VitalBMINodeData): false {
    return false;
  }
}

export const vitalBMINodeSpec = new VitalBMINodeSpec();
